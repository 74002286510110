import { getScoreForm } from "./score_utils";

function updateErrorVisibility(id, isActive, context) {
    const { cleanUp } = context;
    const error = document.getElementById(id);

    if (!error) {
        return;
    }

    if (!isActive) {
        error.classList.add("invisible");
    } else if (isActive && !cleanUp) {
        error.classList.remove("invisible");
    }
}

function highlightContainer(id, isActive, context) {
    const { cleanUp } = context;
    const container = document.getElementById(id);

    if (!container) {
        return;
    }

    if (!isActive) {
        container.classList.remove("extra-error");
    } else if (isActive && !cleanUp) {
        container.classList.add("extra-error");
    }
}

export function highlightVisitContainer(isActive, context) {
    highlightContainer("data-repeater-container", isActive, context);
}

export function highlightHasUsedContainer(isActive, context) {
    highlightContainer("start-treatment-container", isActive, context);
}

export function highlightTreatmentsContainer(isActive, context) {
    highlightContainer("treatment-repeater-container", isActive, context);
}

export function ageError(isActive, context) {
    updateErrorVisibility("age-range-error", isActive, context);
}

export function uniqueVisitsError(isActive, context) {
    updateErrorVisibility("unique-visit-error", isActive, context);
}

export function minVisitsRowError(isActive, context) {
    updateErrorVisibility("min-rows-error", isActive, context);
}

export function shortTimeDifferenceError(isActive, context) {
    updateErrorVisibility("year-diff-error", isActive, context);
}

export function rightCylinderError(isActive, context) {
    updateErrorVisibility("cylinder-right-dependent-error", isActive, context);
}

export function leftCylinderError(isActive, context) {
    updateErrorVisibility("cylinder-left-dependent-error", isActive, context);
}

export function rightAxisError(isActive, context) {
    updateErrorVisibility("axis-right-dependent-error", isActive, context);
}

export function leftAxisError(isActive, context) {
    updateErrorVisibility("axis-left-dependent-error", isActive, context);
}

export function startDateError(isActive, context) {
    updateErrorVisibility("start-date-beyond-error", isActive, context);
}

export function emptyHasUsedTreatmentError(isActive, context) {
    updateErrorVisibility("has-used-treatment-error", isActive, context);
}

export function stopDateBeforeStartError(isActive, context) {
    updateErrorVisibility("greater-stop-date-error", isActive, context);
}

export function requiredStopDateError(isActive, context) {
    updateErrorVisibility("stop-date-error", isActive, context);
}

export function sphereAxialError(isActive, context) {
    updateErrorVisibility("visits-error", isActive, context);
}

export function scoreSubmitError(isActive, context) {
    updateErrorVisibility("score-submit-error", isActive, context);
}

export function missingVisitDataError(isActive, context) {
    updateErrorVisibility("visit-submit-error", isActive, context);
}

export function disableSubmit(isActive, context) {
    const { cleanUp } = context;
    const submit = getScoreForm().querySelector("button[type=submit]");

    if (!isActive) {
        submit.disabled = false;
    } else if (isActive && !cleanUp) {
        submit.disabled = true;
    }
}
