import { onSubmitScoreWithDob } from "../../age";
import {
    disableRows,
    isStartDatePastLatestVisit,
} from "../../form_validator/extensions/collection/common/visit_repeater";
import {
    ageError,
    disableSubmit,
    emptyHasUsedTreatmentError,
    highlightHasUsedContainer,
    highlightTreatmentsContainer,
    highlightVisitContainer,
    leftAxisError,
    leftCylinderError,
    minVisitsRowError,
    missingVisitDataError,
    requiredStopDateError,
    rightAxisError,
    rightCylinderError,
    scoreSubmitError,
    shortTimeDifferenceError,
    sphereAxialError,
    startDateError,
    stopDateBeforeStartError,
    uniqueVisitsError,
} from "./score_effects";
import {
    belowMinVisitRows,
    emptyHasUsedTreatment,
    emptyStopDateWhenStoppedTreatment,
    incorrectAgeRange,
    invalidTreatmentsSchema,
    invalidVisitsSchema,
    isVisitRowMissingData,
    isSphereAndAxialInvalid,
    isStopDateSmallerThanStartDate,
    missingLeftAxis,
    missingLeftCylinder,
    missingRightAxis,
    missingRightCylinder,
    nonUniqueVisitDates,
    shortTimeDifference,
    isSubmitDisabled,
    invalidDemographicSchema,
} from "./score_rules";
import {
    getDemographicSchema,
    getVisitsSchema,
    getTreatmentsSchema,
} from "./score_schemas";
import {
    getInputsData,
    getPartial,
    getScoreForm,
    handleCustomValidations,
    handleSchemaValidation,
    isTreatmentsInputsEmpty,
    normalizeScoreData,
    getValidationMessages,
    isVisitsInputsEmpty,
    ScoreForms,
} from "./score_utils";

const visitsRules = {
    highlightVisitContainer: [
        incorrectAgeRange,
        nonUniqueVisitDates,
        belowMinVisitRows,
        shortTimeDifference,
        missingRightCylinder,
        missingLeftCylinder,
        missingRightAxis,
        missingLeftAxis,
        invalidVisitsSchema,
        isSphereAndAxialInvalid,
        isVisitRowMissingData,
    ],
    ageError: [incorrectAgeRange],
    uniqueVisitsError: [nonUniqueVisitDates],
    minVisitsRowError: [belowMinVisitRows],
    shortTimeDifferenceError: [shortTimeDifference],
    rightCylinderError: [missingRightCylinder],
    leftCylinderError: [missingLeftCylinder],
    rightAxisError: [missingRightAxis],
    leftAxisError: [missingLeftAxis],
    sphereAxialError: [isSphereAndAxialInvalid],
    missingVisitDataError: [isVisitRowMissingData],
};

const treatmentsRules = {
    highlightTreatmentsContainer: [
        invalidTreatmentsSchema,
        isStartDatePastLatestVisit,
        isStopDateSmallerThanStartDate,
        emptyStopDateWhenStoppedTreatment,
    ],

    highlightHasUsedContainer: [emptyHasUsedTreatment],
    startDateError: [isStartDatePastLatestVisit],
    emptyHasUsedTreatmentError: [emptyHasUsedTreatment],
    stopDateBeforeStartError: [isStopDateSmallerThanStartDate],
    requiredStopDateError: [emptyStopDateWhenStoppedTreatment],
};

const commons = [
    ...Object.values(visitsRules).flat(),
    ...Object.values(treatmentsRules).flat(),
    invalidDemographicSchema,
];
const commonRules = {
    scoreSubmitError: commons,
    disableSubmit: commons,
};

const effects = {
    highlightVisitContainer,
    highlightTreatmentsContainer,
    highlightHasUsedContainer,
    uniqueVisitsError,
    ageError,
    minVisitsRowError,
    shortTimeDifferenceError,
    rightCylinderError,
    leftCylinderError,
    rightAxisError,
    leftAxisError,
    startDateError,
    emptyHasUsedTreatmentError,
    stopDateBeforeStartError,
    requiredStopDateError,
    sphereAxialError,
    missingVisitDataError,
    scoreSubmitError,
    disableSubmit,
};

export function initScoreValidation() {
    const form = getScoreForm();

    if (!form) {
        return;
    }

    const scoreRowContainer = document.getElementById("score-row-form");
    const inputs = form.querySelectorAll("input:not([type='radio']),select");
    const radios = form.querySelectorAll("input[type='radio']");
    const addVisitRow = form.querySelector("#score-row-form-submitter");
    const addTreatmentRow = form.querySelector(
        "#score-treatment-form-submitter"
    );
    const removeVisitButtons = scoreRowContainer.querySelectorAll(
        ".remove-repeater-item"
    );
    const messageElements = document.querySelectorAll("[data-v-messages]");
    const messages = getValidationMessages(messageElements);
    const demographicSchema = getDemographicSchema(messages);
    const visitsSchema = getVisitsSchema(messages);
    const treatmentsSchema = getTreatmentsSchema(messages);
    const loader = document.getElementById("loader-submit-score-form");

    const allInputs = () => {
        const checkedRadios = [...radios].filter((radio) => radio.checked);
        return [...inputs, ...checkedRadios];
    };

    const allData = () => {
        return normalizeScoreData(getInputsData(allInputs()));
    };

    const validateDemographic = (context) => {
        if (form.id !== ScoreForms.NEW) {
            return true;
        }
        return handleSchemaValidation(demographicSchema, context);
    };

    const validateVisits = (context, excludeRules = [], includeRules = []) => {
        const isVisitsSchemaValid = handleSchemaValidation(
            visitsSchema,
            context
        );
        const isVisitsRulesValid = handleCustomValidations(
            visitsRules,
            effects,
            context,
            excludeRules,
            includeRules
        );
        return isVisitsSchemaValid && isVisitsRulesValid;
    };

    const validateTreatments = (
        context,
        excludeRules = [],
        includeRules = []
    ) => {
        const { data } = context;
        const shouldValidate =
            data.hasUsedTreatment === "yes" && !isTreatmentsInputsEmpty();
        const isTreatmentsSchemaValid = shouldValidate
            ? handleSchemaValidation(treatmentsSchema, context)
            : true;
        const isTreatmentsRulesValid = handleCustomValidations(
            treatmentsRules,
            effects,
            context,
            excludeRules,
            includeRules
        );
        return isTreatmentsSchemaValid && isTreatmentsRulesValid;
    };

    const validateCommons = (context, excludeRules = [], includeRules = []) => {
        return handleCustomValidations(
            commonRules,
            effects,
            context,
            excludeRules,
            includeRules
        );
    };

    const validate = (context) => {
        const isDemographicValid = validateDemographic(context);
        const isVisitsValid = validateVisits(context);
        const isTreatmentsValid = validateTreatments(context);
        const isCommonsValid = validateCommons(context);

        return (
            isCommonsValid &&
            isDemographicValid &&
            isVisitsValid &&
            isTreatmentsValid
        );
    };

    const handleSubmit = () => {
        const context = {
            data: allData(),
            inputs: [...inputs, ...radios],
            cleanUp: false,
            source: form,
            messages: messages,
            formId: form.id,
        };

        if (validate(context)) {
            onSubmitScoreWithDob(form);
            disableRows("visit-input-repeater");
            disableRows("treatment-input-repeater");
            loader.classList.remove("d-none");
            form.submit();
        }
    };

    const handleInput = (input) => {
        const context = {
            data: allData(),
            inputs: [...inputs, ...radios],
            cleanUp: isSubmitDisabled() ? false : true,
            source: input,
            messages: messages,
            formId: form.id,
        };
        validate(context);
    };

    form.addEventListener("submit", (event) => {
        event.preventDefault();
        handleSubmit();
    });

    [...inputs, ...radios].forEach((input) => {
        input.addEventListener("input", () => {
            handleInput(input);
        });

        input.addEventListener("input-autocomplete", () => {
            handleInput(input);
        });
    });

    addVisitRow.addEventListener("add-repeater-row", (event) => {
        event.preventDefault();

        if (isVisitsInputsEmpty()) {
            return;
        }

        const context = {
            data: allData(),
            inputs: [...inputs, ...radios],
            cleanUp: false,
            source: addVisitRow,
            messages: messages,
            formId: form.id,
        };
        const areVisitsValid = validateVisits(context, [
            isSphereAndAxialInvalid,
            belowMinVisitRows,
        ]);

        if (areVisitsValid) {
            event.detail.trigger();
        } else {
            validateVisits(context);
        }
    });

    addTreatmentRow.addEventListener("add-repeater-row", (event) => {
        event.preventDefault();

        if (isTreatmentsInputsEmpty()) {
            return;
        }

        const data = getPartial(allData(), Object.keys(treatmentsSchema.shape));
        const context = {
            data: allData(),
            inputs: [...inputs, ...radios],
            cleanUp: false,
            source: addTreatmentRow,
            messages: messages,
            formId: form.id,
        };

        const isTreatmentsSchemaValid =
            treatmentsSchema.safeParse(data)?.success;

        if (isTreatmentsSchemaValid) {
            event.detail.trigger();
        } else {
            validateTreatments(context);
        }
    });

    removeVisitButtons.forEach((button) => {
        button.addEventListener("removed-repeater-row", (event) => {
            event.preventDefault();
            const context = {
                data: allData(),
                inputs: [...inputs, ...radios],
                cleanUp: false,
                source: button,
                messages: messages,
                formId: form.id,
            };
            event.detail.trigger();
            validateVisits(context, [], [incorrectAgeRange]);
        });
    });

    // Hide loader when user navigate back to the form page.
    window.addEventListener("beforeunload", () => {
        setTimeout(() => {
            loader.classList.add("d-none");
        }, 8000);
    });
}
