import { isBaseRowEmpty } from "../../form_validator/extensions/collection/common/visit_repeater";
import {
    areAllValuesPresent,
    empty,
    isForm,
    kebabToCamelCase,
    parseFormat,
} from "../../utils";

export const ScoreForms = Object.freeze({
    NEW: "submit-new-score-form",
    UPDATE: "submit-update-score-form",
    UPDATE_TREATED: "submit-use-score-form",
});

export function normalizeInputName(inputName) {
    return kebabToCamelCase(inputName?.replace("_", "-"));
}

export function getInputsData(inputs) {
    return [...inputs].reduce((prev, curr) => {
        const name = normalizeInputName(curr.name);
        prev[name] = curr.value;
        return prev;
    }, {});
}

export function getPartial(obj, keys = []) {
    if (!keys.length) {
        return obj;
    }

    return keys.reduce((partial, key) => {
        if (key in obj) {
            partial[key] = obj[key];
        }
        return partial;
    }, {});
}

export function getDemographicData(data) {
    return getPartial(data, [
        "name",
        "patientId",
        "ethnicity",
        "sex",
        "dobDd",
        "dobMm",
        "dobYyyy",
    ]);
}

export function getVisitsData(data) {
    return getPartial(data, [
        "visitDate",
        "sphereRight",
        "sphereLeft",
        "cylinderRight",
        "cylinderLeft",
        "axisRight",
        "axisLeft",
        "axialLengthRight",
        "axialLengthLeft",
    ]);
}

export function getTreatmentsData(data) {
    return getPartial(data, [
        "hasUsedTreatment",
        "selectTreatment",
        "selectEyes",
        "selectStatus",
        "startDate",
        "stopDate",
    ]);
}

export function getErrorMessages(issues) {
    return issues.reduce((prev, issue) => {
        prev[issue.path[0]] = issue.message;
        return prev;
    }, {});
}

export function handleSchemaValidation(schema, context) {
    const { data, source, inputs } = context;
    const name = source?.name;
    const keys = getSchemaKeys(schema);
    const schemaData = getPartial(context.data, keys);
    const validation = schema.safeParse(schemaData);
    const inputValidation = validateSingleField(schema, name, data[name]);
    const shouldValidateSingleField =
        !isForm(source) && inputValidation && !empty(name);

    if (validation.success) {
        hideSchemaMessages(validation);
        hideErrorClasses(validation, inputs);
        return true;
    }

    if (shouldValidateSingleField) {
        hideMessage(normalizeInputName(name));
        hideErrorClass(inputs, normalizeInputName(name));
    }

    if (!context.cleanUp) {
        showSchemaMessages(validation, keys);
        showErrorClasses(validation, inputs);
    }

    return false;
}

export function handleCustomValidations(
    effectRuleMap,
    effects,
    context,
    excludeRules = [],
    includeRules = []
) {
    let success = true;
    Object.entries(effectRuleMap).forEach(([effect, rules]) => {
        let _rules = rules;

        if (effect in effects) {
            const effectFunction = effects[effect];

            if (excludeRules.length) {
                _rules = rules.filter((rule) => !excludeRules.includes(rule));
            } else if (includeRules.length) {
                _rules = rules.filter((rule) => includeRules.includes(rule));
            }

            const isActive = _rules.some((rule) => !!rule(context));

            effectFunction(isActive, context);

            if (isActive) {
                success = false;
            }
        }
    });

    return success;
}

export function isVisitsInputsEmpty() {
    return isBaseRowEmpty("visit-input-repeater")[0];
}

export function isTreatmentsInputsEmpty() {
    return isBaseRowEmpty("treatment-input-repeater")[0];
}

export function getSchemaKeys(schema) {
    return Object.keys(schema.shape);
}

export function showMessage(name, messages) {
    const error = document.querySelector(`[data-validator-error=${name}`);

    if (error) {
        error.classList.remove("invisible");
        error.innerText = messages[name];
    }
}
export function hideMessage(name) {
    const error = document.querySelector(`[data-validator-error=${name}`);

    if (error) {
        error.classList.add("invisible");
        error.innerText = ".";
    }
}

export function showSchemaMessages(validation, keys) {
    const issues = validation?.error?.issues ?? [];
    const messages = getErrorMessages(issues);
    const issuesKeys = issues.map((issue) => issue.path[0]);
    const succeded = keys.filter((key) => !issuesKeys.includes(key));

    issues.forEach((issue) => {
        const [path] = issue.path;
        showMessage(path, messages);
    });

    succeded.forEach((key) => {
        hideMessage(key);
    });
}

export function getInputErrorParams(input) {
    const errorSelector = input.dataset.errorSelector
        ? input.closest(input.dataset.errorSelector)
        : input;
    const { errorClass } = input.dataset;

    return { errorSelector, errorClass };
}

export function hideSchemaMessages(validation) {
    Object.keys(validation.data).forEach((name) => {
        hideMessage(name);
    });
}

export function showErrorClasses(validation, inputs) {
    const issues = validation?.error?.issues ?? [];

    issues.forEach((issue) => {
        const [path] = issue.path;
        showErrorClass(inputs, path);
    });
}

export function hideErrorClasses(validation, inputs) {
    Object.keys(validation.data).forEach((name) => {
        hideErrorClass(inputs, name);
    });
}

export function showErrorClass(inputs, name) {
    const input = inputs.find(
        (input) => normalizeInputName(input.name) === name
    );

    if (input) {
        const { errorSelector, errorClass } = getInputErrorParams(input);
        errorSelector.classList.add(errorClass);
    }
}

export function hideErrorClass(inputs, name) {
    const input = inputs.find(
        (input) => normalizeInputName(input.name) === name
    );

    if (input) {
        const { errorSelector, errorClass } = getInputErrorParams(input);
        errorSelector.classList.remove(errorClass);
    }
}

function formatVisits(list, format) {
    return [...list].map((item) =>
        item.value !== "-" && item.value !== ""
            ? parseFormat(format, item.value, true)?.[0]
            : undefined
    );
}

export function generateVisitsFieldMap(rows) {
    return rows.reduce((prev, curr) => {
        const date = curr.querySelector("input[name=visit-date]");
        const spheres = curr.querySelectorAll("[name^=sphere]");
        const axials = curr.querySelectorAll("[name^=axial-length]");

        prev[date.value] = {
            sphere: formatVisits(spheres, "0.00D"),
            axial: formatVisits(axials, "0.00mm"),
        };

        return prev;
    }, {});
}

export function hasIncorrectSphereOrAxial(fieldMap, minRows) {
    const res = Object.values(fieldMap)
        .slice(0, minRows)
        .every((field) => {
            const [, { sphere, axial }] = field;

            const hasValidSpheres = areAllValuesPresent(sphere);
            const hasValidAxials = areAllValuesPresent(axial);

            return hasValidSpheres || hasValidAxials;
        });

    return !res;
}

export function validateSingleField(schema, name, value) {
    const inputValidation = schema
        .pick({ [name]: true })
        .safeParse({ [name]: value });
    return inputValidation?.success;
}

export function normalize(value, format = undefined) {
    if (empty(value)) {
        return undefined;
    }

    switch (format) {
        case "number":
            return Number(value);
        case "date":
            return new Date(value);
        default:
            return value;
    }
}

export function getValidationMessages(errorMessageElements) {
    return [...errorMessageElements].reduce((prev, error) => {
        const messages = JSON.parse(error.dataset.vMessages);
        prev[error.dataset.validatorError] = messages;
        return prev;
    }, {});
}

/**
 * Get whichever score form is available.
 * @returns {HTMLFormElement}
 */
export function getScoreForm() {
    const form =
        document.getElementById(ScoreForms.NEW) ||
        document.getElementById(ScoreForms.UPDATE_TREATED) ||
        document.getElementById(ScoreForms.UPDATE);

    return form;
}

export function normalizeScoreData(data) {
    return {
        name: normalize(data.name),
        patientId: normalize(data.patientId),
        ethnicity: normalize(data.ethnicity),
        sex: normalize(data.sex),
        dobDd: normalize(data.dobDd, "number"),
        dobMm: normalize(data.dobMm, "number"),
        dobYyyy: normalize(data.dobYyyy, "number"),
        visitDate: normalize(data.visitDate, "date"),
        sphereRight: normalize(data.sphereRight, "number"),
        cylinderRight: normalize(data.cylinderRight, "number"),
        axisRight: normalize(data.axisRight, "number"),
        axialLengthRight: normalize(data.axialLengthRight, "number"),
        sphereLeft: normalize(data.sphereLeft, "number"),
        cylinderLeft: normalize(data.cylinderLeft, "number"),
        axisLeft: normalize(data.axisLeft, "number"),
        axialLengthLeft: normalize(data.axialLengthLeft, "number"),
        hasUsedTreatment: normalize(data.hasUsedTreatment),
        selectTreatment: normalize(data.selectTreatment),
        startDate: normalize(data.startDate, "date"),
        selectStatus: normalize(data.selectStatus),
        stopDate: normalize(data.stopDate, "date"),
        selectEyes: normalize(data.selectEyes),
    };
}

export function getMinVisits() {
    const minVisitsError = document.getElementById("visits-error");
    const min = minVisitsError?.dataset?.min;
    return min ? Number(min) : 0;
}
