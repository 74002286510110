import { validDate } from "../../date_of_birth";
import {
    areVisitDatesUnique,
    getRepeaterRows,
    hasCorrectAgeRange,
    hasEnoughRows,
    isTimeDifferenceValid,
} from "../../form_validator/extensions/collection/common/visit_repeater";
import { TreatmentStatus } from "../../form_validator/extensions/constants";
import { empty } from "../../utils";
import {
    getDemographicSchema,
    getTreatmentsSchema,
    getVisitsSchema,
} from "./score_schemas";
import {
    generateVisitsFieldMap,
    getDemographicData,
    getMinVisits,
    getTreatmentsData,
    getVisitsData,
    hasIncorrectSphereOrAxial,
    ScoreForms,
} from "./score_utils";

function checkCylinder(cylinder, axis) {
    return empty(cylinder) && !empty(axis);
}

function checkAxis(axis, cylinder) {
    return !empty(cylinder) && empty(axis);
}

export function incorrectAgeRange() {
    return !hasCorrectAgeRange();
}

export function nonUniqueVisitDates() {
    return !areVisitDatesUnique("visit-input-repeater");
}

export function belowMinVisitRows() {
    return !hasEnoughRows("visit-input-repeater", getMinVisits());
}

export function shortTimeDifference() {
    return !isTimeDifferenceValid("visit-input-repeater");
}

export function missingRightCylinder(context) {
    const { data } = context;
    return checkCylinder(data.cylinderRight, data.axisRight);
}

export function missingLeftCylinder(context) {
    const { data } = context;
    return checkCylinder(data.cylinderLeft, data.axisLeft);
}

export function missingRightAxis(context) {
    const { data } = context;
    return checkAxis(data.axisRight, data.cylinderRight);
}

export function missingLeftAxis(context) {
    const { data } = context;
    return checkAxis(data.axisLeft, data.cylinderLeft);
}

export function invalidDemographicSchema(context) {
    const { data, messages } = context;
    const demographicData = getDemographicData(data);
    const demographicValidation =
        getDemographicSchema(messages).safeParse(demographicData);
    return !demographicValidation?.success;
}

export function invalidVisitsSchema(context) {
    const { data, messages } = context;
    const visitsData = getVisitsData(data);
    const visitsValidation = getVisitsSchema(messages).safeParse(visitsData);
    return !visitsValidation?.success;
}

export function invalidTreatmentsSchema(context) {
    const { data, messages } = context;
    if (data.hasUsedTreatment === "yes") {
        const treatmentsData = getTreatmentsData(data);
        const treatmentsValidation =
            getTreatmentsSchema(messages).safeParse(treatmentsData);

        return !treatmentsValidation?.success;
    }

    return false;
}

export function emptyHasUsedTreatment(context) {
    const { data, messages, formId } = context;

    if (formId === ScoreForms.UPDATE_TREATED) {
        return false;
    }

    const { hasUsedTreatment } = data;
    const validation = getTreatmentsSchema(messages)
        .pick({ hasUsedTreatment: true })
        .safeParse({ hasUsedTreatment });
    return !validation?.success;
}

export function isStopDateSmallerThanStartDate(context) {
    const { data } = context;
    const stopDateValid = validDate(data.stopDate);

    const stopValue = stopDateValid ? new Date(data.stopDate) : null;

    const startValue = stopDateValid ? new Date(data.startDate) : null;

    const isStopBeforeStart = stopValue < startValue;
    const isOngoingStatus =
        !empty(data.selectStatus) &&
        data.selectStatus === TreatmentStatus.ONGOING;

    return isStopBeforeStart && !isOngoingStatus;
}

export function emptyStopDateWhenStoppedTreatment(context) {
    const { data } = context;

    return (
        (data.selectStatus === TreatmentStatus.STOPPED ||
            data.selectStatus === TreatmentStatus.STOP) &&
        empty(data.stopDate)
    );
}

export function isSphereAndAxialInvalid() {
    const rows = getRepeaterRows("visit-input-repeater", false);
    const fieldMap = generateVisitsFieldMap(rows);

    const sortedFieldMap = Object.entries(fieldMap)
        .sort(([keyA], [keyB]) => new Date(keyB) - new Date(keyA))
        .slice(0, this.minRows);

    if (sortedFieldMap.length >= getMinVisits()) {
        return hasIncorrectSphereOrAxial(sortedFieldMap);
    }

    return true;
}

export function isIncompletePair(value1, value2) {
    return empty(value1) && !empty(value2);
}

export function isIncompleteSpherePair(sphere1, sphere2) {
    return (
        isIncompletePair(sphere1, sphere2) || isIncompletePair(sphere2, sphere1)
    );
}

export function isIncompleteAxialLengthPair(axial1, axial2) {
    return isIncompletePair(axial1, axial2) || isIncompletePair(axial2, axial1);
}

export function isVisitRowMissingData(context) {
    const { data } = context;
    const { sphereRight, sphereLeft, axialLengthRight, axialLengthLeft } = data;

    return (
        isIncompleteSpherePair(sphereRight, sphereLeft) ||
        isIncompleteAxialLengthPair(axialLengthRight, axialLengthLeft)
    );
}

export function isSubmitDisabled() {
    const submit = document.getElementById("submit-new-score-form-submitter");
    return submit?.disabled ?? false;
}
