import { z } from "zod";

export function getDemographicSchema(m) {
    return z.object({
        name: z
            .string({ message: m?.name?.string })
            .min(1, m?.name?.min)
            .max(999, m?.name?.max),
        patientId: z
            .string({ message: m?.patientId?.string })
            .max(999, m?.patientId?.max)
            .optional(),
        ethnicity: z
            .string({ message: m?.ethnicity?.string })
            .min(1, m?.ethnicity?.min)
            .max(999, m?.ethnicity?.max),
        sex: z
            .string({ message: m?.sex?.string })
            .min(1, m?.sex?.min)
            .max(999, m?.sex?.max),
        dobDd: z
            .number({ message: m?.dobDd?.number })
            .gte(1, m?.dobDd?.gte)
            .lte(31, m?.dobDd?.lte),
        dobMm: z
            .number({ message: m?.dobMm?.number })
            .gte(1, m?.dobMm?.gte)
            .lte(12, m?.dobMm?.lte),
        dobYyyy: z
            .number({ message: m?.dobYyyy?.number })
            .gte(0, m?.dobYyyy?.gte)
            .lte(9999, m?.dobYyyy?.lte),
    });
}

export function getVisitsSchema(m) {
    return z.object({
        visitDate: z
            .date({
                errorMap: () => ({ message: m?.visitDate?.max }),
                message: m.visitDate.required,
            })
            .max(new Date(), m.visitDate.invalid),
        sphereRight: z
            .number({ message: m.sphereRight.required })
            .gte(-9, { message: m.sphereRight?.gte })
            .lte(9, { message: m.sphereRight?.lte })
            .optional(),
        sphereLeft: z
            .number({ message: m.sphereLeft.required })
            .gte(-9, { message: m.sphereLeft?.gte })
            .lte(9, { message: m.sphereLeft?.lte })
            .optional(),
        cylinderRight: z
            .number({ message: m.cylinderRight.required })
            .gte(-6, { message: m.cylinderRight?.gte })
            .lte(6, { message: m.cylinderRight?.lte })
            .optional(),
        cylinderLeft: z
            .number({ message: m.cylinderLeft.required })
            .gte(-6, { message: m.cylinderLeft?.gte })
            .lte(6, { message: m.cylinderLeft?.lte })
            .optional(),
        axisRight: z
            .number({ message: m.axisRight?.required })
            .gte(0, { message: m.axisRight?.gte })
            .lte(180, { message: m.axisRight?.lte })
            .optional(),
        axisLeft: z
            .number({ message: m.axisLeft.required })
            .gte(0, { message: m.axisLeft?.gte })
            .lte(180, { message: m.axisLeft?.lte })
            .optional(),
        axialLengthRight: z
            .number({ message: m.axialRight.required })
            .gte(20, { message: m.axialRight?.gte })
            .lte(28, { message: m.axialRight?.lte })
            .optional(),
        axialLengthLeft: z
            .number({ message: m.axialLeft.required })
            .gte(20, { message: m.axialLeft?.gte })
            .lte(28, { message: m.axialLeft?.lte })
            .optional(),
    });
}

export function getTreatmentsSchema(m) {
    return z.object({
        hasUsedTreatment: z
            .string({ message: m?.hasUsedTreatment?.required })
            .max(999, m?.hasUsedTreatment?.max)
            .optional(),
        selectTreatment: z
            .string({ message: m.selectTreatment.required })
            .min(1, m.selectTreatment.min)
            .max(999, m.selectTreatment.max)
            .optional(),
        selectEyes: z
            .string({ message: m.selectEyes.required })
            .min(1, m.selectEyes.min)
            .max(999, m.selectEyes.max)
            .optional(),
        selectStatus: z
            .string({ message: m.selectStatus.required })
            .min(1, m.selectStatus.min)
            .max(999, m.selectStatus.max)
            .optional(),
        startDate: z
            .date({
                errorMap: () => ({ message: m?.startDate?.required }),
                message: m.startDate.required,
            })
            .max(new Date(), m?.startDate?.max)
            .optional(),
        stopDate: z
            .date({
                errorMap: () => ({ message: m?.stopDate?.required }),
                message: m?.stopDate?.required,
            })
            .max(new Date(), m?.stopDate?.max)
            .optional(),
    });
}
