import { breakpoints, clientWidth, isBreakpoint } from "./breakpoints";
import { validDate } from "./libs/date_of_birth";
import {
    getVisitDatesInputs,
    getRepeaterRows,
} from "./libs/form_validator/extensions/collection/common/visit_repeater.js";
import { TreatmentStatus } from "./libs/form_validator/extensions/constants";
import {
    formatDate,
    formatISODate,
    getInputs,
    isDesktopBreakpoint,
    isMobileUserAgent,
    stringToBoolean,
    swapClasses,
    swapClassesIf,
} from "./libs/utils";
import Modal from "bootstrap/js/dist/modal";
import { initScoreValidation } from "./libs/validation/score/score_validation.js";

const DOT_SIZE = 17;
const DOT_GAP = 10;
const DOT_OFFSET = DOT_SIZE + DOT_GAP;

/**
 * Initialise swipe index functionalities.
 */
function initSwipeIndex() {
    const swipes = document.querySelectorAll(".swipe-index-content");

    if (!swipes?.length) {
        return;
    }

    swipes.forEach((swipe) => handleSwipeIndex(swipe));
}

/**
 * Handle events and functionalities on swipe index elements.
 * @param {HTMLElement} swipe
 */
function handleSwipeIndex(swipe) {
    const [id] = swipe.id.split("-");
    const rightContent = document.querySelectorAll(".right-content.responsive");
    const leftContent = document.querySelectorAll(".left-content.responsive");
    const indicator = document.getElementById(`${id}-swipe-indicator`);
    const indicatorContainer = document.querySelector(".indicator-container");
    const dots = indicator.querySelectorAll(".dot-index-indicator");
    const leftArrow = indicator.querySelector(".indicator-left-arrow");
    const rightArrow = indicator.querySelector(".indicator-right-arrow");
    const scrollContainer = document.querySelector(".score-scroll-snap");
    const scrollChilds = scrollContainer.querySelectorAll(".score-snap-child");

    /**
     * Scroll container to show the next/previous indicator.
     * @param {number} value
     */
    const handleClickArrow = (value) => {
        indicatorContainer.scroll({
            top: 0,
            left: indicatorContainer.scrollLeft + value,
            behavior: "smooth",
        });
    };

    /**
     * Listen to arrows events.
     */
    const setupArrowsListeners = () => {
        leftArrow.addEventListener("click", () =>
            handleClickArrow(-DOT_OFFSET)
        );

        rightArrow.addEventListener("click", () =>
            handleClickArrow(DOT_OFFSET)
        );

        indicatorContainer.addEventListener("scroll", showIndicatorArrows);

        window.addEventListener("resize", () => {
            if (!isDesktopBreakpoint()) {
                setActiveIndicator();
                showIndicatorArrows();
                indicator.dataset.currentIndex = 0;
            }
        });
    };

    /**
     * Show/hide arrows based on indicator selection."
     */
    const showIndicatorArrows = () => {
        const hideLeft = indicatorContainer.scrollLeft <= 0;
        swapClassesIf(hideLeft, leftArrow, "invisible", "visible");

        const hideRight =
            indicatorContainer.scrollLeft ===
            indicatorContainer.scrollWidth - indicatorContainer.clientWidth;
        swapClassesIf(hideRight, rightArrow, "invisible", "visible");
    };

    /**
     * Check which indicator element matches the current selected index
     * and change its styles accordingly.
     * @param {HTMLElement|undefined} dotIndicator
     */
    const setActiveIndicator = (dotIndicator = undefined) => {
        if (dotIndicator) {
            indicator.dataset.currentIndex = dotIndicator.dataset.index;
        }

        dots.forEach((dot) => {
            if (dot.dataset.index === indicator.dataset.currentIndex) {
                dot.classList.add("active");
            } else {
                dot.classList.remove("active");
            }
        });
    };

    /**
     * Get content corresponding to the selected indicator index.
     * @returns {HTMLElement}
     */
    const getCurrentSnapChild = () => {
        return [...scrollChilds].find(
            (child) => child.dataset.index === indicator.dataset.currentIndex
        );
    };

    /**
     * Scrolls into the selected content and update indicators state accordingly.
     * @param {HTMLElement} dotIndicator
     */
    const handleClickIndicator = (dotIndicator) => {
        setActiveIndicator(dotIndicator);
        getCurrentSnapChild().scrollIntoView({
            inline: "nearest",
            block: "nearest",
            behavior: "smooth",
        });
    };

    /**
     * Listen to click events on indicators.
     */
    const setupIndicatorListeners = () => {
        dots.forEach((dotIndicator) => {
            dotIndicator.addEventListener("click", () => {
                handleClickIndicator(dotIndicator);
            });
        });
    };

    /**
     * Show right content and hide left content.
     */
    const showRightContent = () => {
        rightContent.forEach((right) => {
            swapClasses(right, "d-block", "d-none");
        });

        leftContent.forEach((left) => {
            swapClasses(left, "d-none", "d-block");
        });
    };

    /**
     * Show left content and hide left content.
     */
    const showLeftContent = () => {
        leftContent.forEach((left) => {
            swapClasses(left, "d-block", "d-none");
        });
        rightContent.forEach((right) => {
            swapClasses(right, "d-none", "d-block");
        });
    };

    /**
     * Update indicators state when a child of scrollable container is
     * fully visible on the screen.
     */
    const observeVisibleContent = () => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveIndicator(entry.target);
                        if (indicator.dataset.currentIndex >= 5) {
                            handleClickArrow(
                                DOT_OFFSET *
                                    (dots.length -
                                        entry.target.dataset.index +
                                        1)
                            );
                        } else if (indicator.dataset.currentIndex < 3) {
                            handleClickArrow(
                                -DOT_OFFSET *
                                    (dots.length -
                                        entry.target.dataset.index -
                                        1)
                            );
                        }
                    }
                });
            },
            { root: scrollContainer, threshold: 1.0 }
        );

        scrollChilds.forEach((child) => {
            observer.observe(child);
        });
    };

    /**
     * Change which part of the content should be shown
     * based on eyes toggle value (left or right).
     * @param {HTMLInputElement} input
     */
    const handleClickEyesToggle = (input) => {
        if (input.dataset.linkId === "right") {
            showRightContent();
        } else {
            showLeftContent();
        }
    };

    /**
     * Listen to eye toggle click events.
     */
    const setupEyesToggleListeners = () => {
        const toggles = document.querySelectorAll("#visits-table-tab input");

        toggles.forEach((input) => {
            input.addEventListener("input", () => handleClickEyesToggle(input));
        });
    };

    /**
     * Computes container maximum height based on child elements height and spacing
     * so that it always displays four elements before enabling the scrollbar.
     */
    const computeContainerMaxHeight = () => {
        const scrollHeading = document.querySelector(".score-scroll-heading");
        const childsSubset = [...scrollChilds].slice(0, 4);
        const isResponsive = scrollContainer.classList.contains("responsive");
        const childsLength = scrollChilds.length;

        if (!isBreakpoint("md") && isResponsive) return;

        const maxHeight = childsSubset.reduce((prev, curr) => {
            const lowerOffset = !isBreakpoint("md") && !isResponsive ? 0 : 10;
            const spacingOffset =
                clientWidth() >= breakpoints.xl ? 15 : lowerOffset;
            prev += curr.clientHeight + spacingOffset;
            return prev;
        }, 0);

        const finalHeight =
            childsLength < 5
                ? "max-content"
                : `${maxHeight + scrollHeading.clientHeight}px`;
        scrollContainer.style.maxHeight = finalHeight;
    };

    /**
     * Initialise container max height value and updates it
     * on screen resize.
     */
    const setupContainerMaxHeight = () => {
        computeContainerMaxHeight();
        window.addEventListener("resize", computeContainerMaxHeight);
    };

    showRightContent();
    setActiveIndicator();
    showIndicatorArrows();
    setupArrowsListeners();
    setupEyesToggleListeners();
    setupIndicatorListeners();
    observeVisibleContent();
    setupContainerMaxHeight();
}

/**
 * Shows date picker on click .score-date-picker button.
 */
function initScoreDatePicker() {
    const buttons = document.querySelectorAll(".score-date-picker");

    buttons.forEach((button) => {
        const input = document.getElementById(button.id.replace("label-", ""));

        if (!input) {
            return;
        }

        input.max = formatISODate(new Date());

        button.addEventListener("click", () => input?.showPicker());
    });
}

/**
 * Handle select treatment status.
 */
function initSelectStatus() {
    const selectContainers = document.querySelectorAll(".select-status-stop");

    selectContainers.forEach((container) => {
        setupSelectStatus(container);
    });
}

/**
 * Add listeners to select treatment status.
 * @param {HTMLElement} container
 */
function setupSelectStatus(container) {
    const select = container.querySelector("select");
    const dateContainer = container.querySelector(".date-container");
    const dateInput = dateContainer.querySelector("input[name=stop-date]");
    const optionDate = select.querySelector("#option-stopped-on-date");
    dateInput.value = "";

    const selectOptionWithDate = () => {
        if (!validDate(dateInput.value)) {
            return;
        }
        optionDate.innerText = `Stopped on ${formatDate(dateInput.value)}`;
        optionDate.selected = true;
        dateContainer.classList.add("d-none");
    };

    select.addEventListener("input", () => {
        const { value } = select;
        dateInput.value = "";

        if (value == "stop") {
            dateContainer.classList.remove("d-none");
        } else {
            dateContainer.classList.add("d-none");
        }
    });

    dateInput.addEventListener("focusout", selectOptionWithDate);
}

/**
 * Handle select start date initialization.
 */
function initSelectStartDate() {
    const selectContainers = document.querySelectorAll(".select-start-date");

    selectContainers.forEach((container) => {
        setupSelectStartDate(container);
    });

    const repeaterId = "visit-input-repeater";
    const visitItems = getRepeaterRows(repeaterId);

    if (visitItems) {
        const selectElement = document.querySelector(
            "select[name='select-start-date']"
        );

        const visitDateInputs = getVisitDatesInputs(visitItems);

        visitDateInputs.forEach((input) => {
            input.addEventListener("input", () => {
                addCustomVisitDates(selectElement, visitDateInputs);
            });
        });

        // Initial population of any custom dates that were already entered
        addCustomVisitDates(selectElement, visitDateInputs);
    }
}

/**
 * Function to sync custom visit dates (new user inputs) with the select element.
 * Ensures that options reflect only the current set of visit dates.
 * @param {HTMLSelectElement} selectElement
 * @param {NodeListOf<HTMLInputElement>} visitDateInputs
 */
export function addCustomVisitDates(selectElement, visitDateInputs) {
    const dates = [
        ...document.querySelectorAll("input[name='visit-date']"),
    ].map((input) => input.value);
    const { options } = selectElement;

    Array.from(options).forEach((option) => {
        if (
            option.classList.contains("custom-added") &&
            !dates.includes(option.value)
        ) {
            selectElement.removeChild(option);
        }
    });

    // Add updated visit date options based on current inputs
    visitDateInputs.forEach((input) => {
        const dateValue = input.value;
        if (
            dateValue &&
            !Array.from(options).some((opt) => opt.value === dateValue)
        ) {
            const option = document.createElement("option");
            option.value = dateValue;
            option.textContent = formatDate(dateValue);
            // Mark this option as dynamically added
            option.classList.add("custom-added");
            selectElement.appendChild(option);
        }
    });

    // Option to enter a custom date at the end
    const customDateOption = selectElement.querySelector(
        "#option-enter-custom"
    );
    if (customDateOption) {
        selectElement.appendChild(customDateOption);
    }
}

/**
 * Function to set up the select start date logic.
 * @param {HTMLElement} container
 */
function setupSelectStartDate(container) {
    const select = container.querySelector("select");
    const dateContainer = container.querySelector(".date-container");
    const dateInput = dateContainer.querySelector("input[name=start-date]");
    dateInput.value = "";

    const selectOptionWithDate = () => {
        if (!validDate(dateInput.value)) {
            return;
        }

        const formattedDate = formatDate(dateInput.value);
        // Create or update the custom date option
        const customOption = new Option(
            formattedDate,
            dateInput.value,
            true,
            true
        );
        customOption.hidden = true;

        // Remove any existing hidden custom option before adding the new one
        const existingCustomOption = select.querySelector(
            "#option-custom-date"
        );
        if (existingCustomOption) {
            select.removeChild(existingCustomOption);
        }

        select.appendChild(customOption);
        select.value = dateInput.value;
        dateContainer.classList.add("d-none");
    };

    select.addEventListener("input", () => {
        const { value } = select;

        if (value === "enter-custom") {
            dateContainer.classList.remove("d-none");
        } else {
            dateInput.value = value;
            dateContainer.classList.add("d-none");
        }
    });

    dateInput.addEventListener("focusout", selectOptionWithDate);
}

/*
 * Show modal imediately if 'prompt' attribute is truthy.
 */
function openPromptModal() {
    if (isMobileUserAgent()) {
        return;
    }

    const promptModalElement = document.querySelector(".prompt-modal");

    if (!promptModalElement) {
        return;
    }

    const prompt = stringToBoolean(promptModalElement?.dataset?.prompt);
    const promptModal = Modal.getOrCreateInstance("#score-prompt-modal");

    if (prompt && promptModal) {
        promptModal.show();
    }
}

function toggleStartTreatment() {
    const container = document.getElementById("start-treatment-container");

    if (!container) {
        return;
    }

    const treatments = document.getElementById("treatment-input-container");
    const toggles = container.querySelectorAll(
        "input[name=has-used-treatment]"
    );
    const treatmentsClass = "toggle";

    const handleYesOption = (inputs) => {
        treatments.classList.remove(treatmentsClass);

        inputs.forEach((input) => {
            input.disabled = false;
        });
    };

    const handleNoOption = (inputs) => {
        treatments.classList.add(treatmentsClass);

        inputs.forEach((input) => {
            input.disabled = true;
        });
    };

    const handleToggle = (toggle) => {
        const inputs = treatments.querySelectorAll("input,select");

        switch (toggle.value) {
            case "yes":
                return handleYesOption(inputs);

            case "no":
                return handleNoOption(inputs);
        }
    };

    toggles.forEach((toggle) => {
        toggle.addEventListener("input", () => handleToggle(toggle));
    });
}

/**
 * Initialise treatment item edition.
 */
function initEditTreatmentItems() {
    const editItems = document.querySelectorAll(".repeater-edit-item");
    editItems.forEach((item) => handleEditItem(item));
}

/**
 *
 * @param {HTMLElement} item
 */
function handleEditItem(item) {
    const repeater = document.getElementById("treatment-input-repeater");
    const eyeInput = item.querySelector("select[name=select-eyes]");
    const statusInput = item.querySelector("select[name=select-status]");
    const stopInput = item.querySelector("input[name=stop-date]");

    const getExtraItems = () =>
        repeater.querySelectorAll(`#left-${item.id}, #right-${item.id}`);

    const shouldCloneItem = () =>
        eyeInput.value !== "both" &&
        stopInput.value &&
        getExtraItems()?.length === 0;

    const getOppositeEye = () =>
        eyeInput.value === "right" ? "left" : "right";

    const addClonedItem = () => {
        if (shouldCloneItem()) {
            const clonedRow = setupRowClone(item, eyeInput.value);
            eyeInput.value = getOppositeEye();
            statusInput.value = "ongoing";
            clonedRow.classList.add("entering");
            repeater.insertBefore(clonedRow, item);
        }
    };

    const removeClones = () => {
        getExtraItems().forEach((item) => item.remove());
    };

    statusInput.addEventListener("input", () => {
        const extraItems = getExtraItems();

        if (
            extraItems?.length &&
            statusInput.value === TreatmentStatus.ONGOING
        ) {
            removeClones();
        }
    });

    eyeInput.addEventListener("input", () => {
        const extraItems = getExtraItems();

        if (extraItems?.length && eyeInput.value === "both") {
            removeClones();
        } else if (extraItems?.length && eyeInput.value !== "both") {
            extraItems.forEach((extraItem) => {
                const eyeInput = extraItem.querySelector(
                    "select[name=select-eyes]"
                );
                eyeInput.value = getOppositeEye();
            });
        } else {
            addClonedItem();
        }
    });

    stopInput.addEventListener("input", addClonedItem);
}

/**
 * Clone treatment row and adjust its values.
 * @param {HTMLElement} item
 * @param {string} eye
 * @returns {Node}
 */
function setupRowClone(item, eye) {
    const clone = item.cloneNode(true);
    const eyeInput = clone.querySelector("select[name=select-eyes]");
    const statusInput = clone.querySelector("select[name=select-status]");

    const inputs = getInputs(clone);

    eyeInput.value = eye;
    statusInput.value = "stopped";

    inputs.forEach((input) => {
        input.id = `${eye}-${input.id}`;
        input.classList.add("input-readonly");
        input.classList.remove("fw-bold");
    });

    clone.id = `${eye}-${item.id}`;
    showDeleteRowButton(clone);
    return clone;
}

/**
 * Show delete button to cloned row item.
 * @param {HTMLElement} item
 */
function showDeleteRowButton(item) {
    const removeButton = item.querySelector("button");
    removeButton.className =
        "circle-button remove-repeater-modal bgc-purple-500 rounded-circle border-0 color-white fs-14 font-semibold";
    removeButton.dataset.bsToggle = "modal";
    removeButton.dataset.bsTarget = "#confirm-delete-repeater";
}
/**
 * Show modal immediately if 'prompt' attribute is truthy.
 */
window.addEventListener("load", function () {
    initSwipeIndex();
    initScoreDatePicker();
    initSelectStatus();
    initSelectStartDate();
    openPromptModal();
    toggleStartTreatment();
    initEditTreatmentItems();
    initScoreValidation();
});
