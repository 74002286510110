export const breakpoints = Object.freeze({
    base: 0,
    xs: 390,
    sm: 640,
    md: 768,
    lg: 1025,
    xl: 1280,
    lxl: 1400,
    xlx: 1512,
    xxl: 1920,
});

// Short-hand function to get client width.
export const clientWidth = () => document.documentElement.clientWidth;

/**
 * Check if client width is within a certain breakpoint.
 * @param {keyof breakpoints} breakpoint
 * @returns {boolean}
 */
export function isBreakpoint(breakpoint) {
    return clientWidth() >= breakpoints[breakpoint];
}
