// eslint-disable-next-line no-unused-vars
import { FormValidator } from "../../../form_validator";
import { areDatesBelowLimit } from "../../../../utils";
import { YEAR_DIFF_LIMIT } from "../../constants";
import { calculateAgeInDecimal } from "../../../../../libs/age";
import { validDate } from "../../../../date_of_birth";

export const BASE_ITEM_CLASS = ".repeater-item-base";
export const ITEM_CLASS = ".repeater-item";

/**
 * Get all repeater rows.
 * @param {string} repeaterId
 * @param {boolean} allowEmpty
 * @returns {HTMLElement[]}
 */
export function getRepeaterRows(repeaterId, allowEmpty = true) {
    const repeater = document.getElementById(repeaterId);

    if (!repeater) {
        return null;
    }

    const base = repeater.querySelector(BASE_ITEM_CLASS);
    const items = repeater.querySelectorAll(ITEM_CLASS);
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    if (!allowEmpty && isEmpty) {
        return [...items];
    }

    return [base, ...items];
}
/**
 * Checks if repeater base row is empty.
 * @param {string} repeaterId
 * @returns {[boolean, NodeListOf<HTMLInputElement>]}
 */
export function isBaseRowEmpty(repeaterId) {
    const repeater = document.getElementById(repeaterId);
    const base = repeater.querySelector(BASE_ITEM_CLASS);

    if (!base) {
        return [true, []];
    }

    const inputs = base.querySelectorAll("input,select");

    const isEmpty = [...inputs]
        .filter((input) => input.type !== "date")
        .every((input) => !input.value);

    return [isEmpty, inputs];
}

/**
 * Disables repeater base row if it is empty.
 * @param {FormValidator} formValidator
 * @param {string} repeaterId
 */
export function disableEmptyRow(formValidator, repeaterId) {
    const [isEmpty, inputs] = isBaseRowEmpty(repeaterId);

    formValidator.validateForm(true, false);

    if (formValidator.isValid && isEmpty) {
        inputs.forEach((input) => {
            input.disabled = true;
        });
    }
}

export function disableRows(repeaterId) {
    const [isEmpty, inputs] = isBaseRowEmpty(repeaterId);

    if (isEmpty) {
        inputs.forEach((input) => {
            input.disabled = true;
        });
    }
}

/**
 * Get all date inputs from visits repeater.
 * @param {NodeListOf<HTMLElement>} visits
 * @returns {NodeListOf<HTMLInputElement>}
 */
export function getVisitDatesInputs(visits) {
    return visits.map((item) => item.querySelector("input[name='visit-date']"));
}

/**
 * Checks if the repeater visit dates are unique.
 * @returns {boolean}
 */
export function areVisitDatesUnique(repeaterId) {
    const visitItems = getRepeaterRows(repeaterId);
    const dateInputs = getVisitDatesInputs(visitItems);

    const [isEmpty] = isBaseRowEmpty(repeaterId);

    const filteredDateInputs = isEmpty
        ? dateInputs.filter((input) => input.id !== "visit-date")
        : dateInputs;

    const dateValues = filteredDateInputs.map((input) => input.value);
    const uniqueDates = new Set(dateValues);

    return dateValues.length === uniqueDates.size;
}
/**
 * Checks if the repeater visit dates have the right age range.
 * @returns {boolean}
 */
export function hasCorrectAgeRange() {
    const repeaterId = "visit-input-repeater";
    let ageInRange = true;
    const visitItems = getRepeaterRows(repeaterId);
    const dateInputs = getVisitDatesInputs(visitItems);

    const [isEmpty] = isBaseRowEmpty(repeaterId);

    const filteredDateInputs = isEmpty
        ? dateInputs.filter((input) => input.id !== "visit-date")
        : dateInputs;

    const yyyyInput = document.getElementById("dob-yyyy");
    const mmInput = document.getElementById("dob-mm");
    const ddInput = document.getElementById("dob-dd");
    if (yyyyInput && mmInput && ddInput) {
        const dob = new Date(
            yyyyInput.value + "/" + mmInput.value + "/" + ddInput.value
        );

        const ageValues = filteredDateInputs.map((input) =>
            calculateAgeInDecimal(dob, new Date(input.value))
        );

        ageValues.forEach((age) => {
            const floatAge = parseFloat(age);
            if (6 > floatAge || floatAge > 18) {
                ageInRange = false;
            }
        });
    }

    return ageInRange;
}

/**
 * Checks if repeater has a certain number of rows.
 * @param {string} repeaterId
 * @param {number} rows
 */
export function hasEnoughRows(repeaterId, rows) {
    const items = getRepeaterRows(repeaterId);
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    const totalRows = isEmpty ? rows + 1 : rows;

    return items.length >= totalRows;
}

export function isTimeDifferenceValid(repeaterId) {
    const visits = getRepeaterRows(repeaterId, false);
    const dateInputs = getVisitDatesInputs(visits);
    const dates = dateInputs.map((item) => new Date(item.value));

    const divergingDates = areDatesBelowLimit(dates, YEAR_DIFF_LIMIT);

    return !divergingDates;
}

export function isStartDatePastLatestVisit() {
    const rows = getRepeaterRows("visit-input-repeater");
    const treatmentRepeater = document.getElementById(
        "treatment-input-repeater"
    );
    const visitInputs = getVisitDatesInputs(rows);
    const start = treatmentRepeater.querySelector(
        "input[name=start-date]"
    ).value;

    const dates = visitInputs.map((input) => new Date(input.value));
    const maxDate = dates.reduce((a, b) => (a > b ? a : b));
    const startDate = new Date(start);

    if (!maxDate || isNaN(maxDate)) {
        return false;
    }

    return startDate > maxDate;
}

/**
 *
 * @param {string} inputDate
 * @param {string} selectDate
 * @returns {Date}
 */
export function getStartDate(inputDate, selectDate) {
    return validDate(inputDate) ? new Date(inputDate) : new Date(selectDate);
}
